import React, { useState, useEffect, useRef } from 'react';
import Wheel from './components/Wheel';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HistoryTable from './components/HistoryTable';
import CharacterTable from './components/CharacterTable';

const SpinWheel = ({ user }) => {
  const [spinType, setSpinType] = useState('basic');
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]); // For Spin x10
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false); // Error modal state
  const [errorMessage, setErrorMessage] = useState(''); // Error message state
  const [isSpinning, setIsSpinning] = useState(false);
  const [spinCounts, setSpinCounts] = useState({ basic: 0, premium: 0, jackpot: 0 });
  const [spinConfigurations, setSpinConfigurations] = useState({ basic: [], premium: [], jackpot: [] });
  const [refreshFlag, setRefreshFlag] = useState(false); // Trigger history refresh

  const [volume, setVolume] = useState(0.5); // Default volume state
  const [isMuted, setIsMuted] = useState(false); // Default muted state

  const wheelRef = useRef(null);
  const spinTimeoutRef = useRef(null);

  useEffect(() => {
    const savedVolume = parseFloat(sessionStorage.getItem('volume')) || 0.5;
    const savedMute = sessionStorage.getItem('muted') === 'true';
    setVolume(savedVolume);
    setIsMuted(savedMute);
  }, []);

  useEffect(() => {
    const fetchSpinConfigurations = async () => {
      try {
        const configResponse = await fetch('/api/spin-config');
        const configData = await configResponse.json();
        setSpinConfigurations(configData);

        const countsResponse = await fetch('/api/get-user-spin-counts', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ userId: user.userId }),
        });

        const countsData = await countsResponse.json();
        setSpinCounts(countsData);
      } catch (error) {
        console.error('Error fetching spin configurations or counts:', error);
      }
    };

    fetchSpinConfigurations();

    return () => {
      if (spinTimeoutRef.current) {
        clearTimeout(spinTimeoutRef.current);
      }
    };
  }, [user]);

  const resetSpinState = () => {
    if (wheelRef.current) {
      wheelRef.current.resetWheel();
    }
    setSelectedItem(null);
    setSelectedItems([]);
    setIsModalOpen(false);
  };

  const handleSpinX10 = async () => {
    if (isSpinning || spinCounts[spinType] < 10) return;
    setIsSpinning(true);
    resetSpinState();

    try {
      const response = await fetch('/api/spin-x10', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.userId, type: spinType }),
      });
      const data = await response.json();

      if (data.error) {
        setErrorMessage(data.error);
        setIsErrorModalOpen(true);
        setIsSpinning(false);
        return;
      }

      if (data.selectedItems && data.selectedItems.length > 0) {
        const selectedItemIndex = spinConfigurations[spinType].findIndex(
          item => item.name === data.selectedItems[0].name
        );
        setSelectedItem(selectedItemIndex);
        setSelectedItems(data.selectedItems);

        wheelRef.current.setState({ spinDuration: '7s' });

        setTimeout(() => {
          setSpinCounts(data.points);
          setIsSpinning(false);
          setIsModalOpen(true);
          setRefreshFlag(prev => !prev);
        }, 7000);
      }
    } catch (error) {
      setErrorMessage('Failed to process x10 spins');
      setIsErrorModalOpen(true);
      setIsSpinning(false);
    }
  };

  const handleSpin = async () => {
    if (isSpinning || spinCounts[spinType] <= 0) return;
    setIsSpinning(true);
    resetSpinState();

    wheelRef.current.setState({
      spinDuration: '7s',
      isSpinning: true,
    });

    try {
      const response = await fetch('/api/spin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId: user.userId, type: spinType }),
      });
      const data = await response.json();

      if (data.error) {
        setErrorMessage(data.error);
        setIsErrorModalOpen(true);
        setIsSpinning(false);
        return;
      }

      if (data.items && data.items.length > 0) {
        const selectedItemIndex = spinConfigurations[spinType].findIndex(
          item => item.name === data.items[0].name
        );
        setSelectedItem(selectedItemIndex);
        setSpinCounts(data.points);

        spinTimeoutRef.current = setTimeout(() => {
          setIsSpinning(false);
          setIsModalOpen(true);
          setRefreshFlag(prev => !prev);
        }, 7500);
      }
    } catch (error) {
      setErrorMessage('Failed to process spin');
      setIsErrorModalOpen(true);
      setIsSpinning(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setRefreshFlag(prev => !prev);
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <div className="container-fluid">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-3">
          <CharacterTable userId={user.userId} />
        </div>
        <div className="col-lg-5 text-center">
          <h1 className='text-shadow-inverted header-spin' style={{ textTransform: 'uppercase' }}>Spin Roulette</h1>
          <div className="tabs mb-3">
            <button onClick={() => setSpinType('basic')} className="btn btn-primary" disabled={isSpinning || isModalOpen}>
              Basic Spin ({spinCounts.basic})
            </button>
            <button onClick={() => setSpinType('premium')} className="btn btn-danger" disabled={isSpinning || isModalOpen}>
              Premium Spin ({spinCounts.premium})
            </button>
            <button onClick={() => setSpinType('jackpot')} className="btn btn-warning" disabled={isSpinning || isModalOpen}>
              Jackpot Spin ({spinCounts.jackpot})
            </button>
          </div>
          <Wheel
            ref={wheelRef}
            items={spinConfigurations[spinType].map(item => ({
              ...item,
              color: item.color || '#FFFFFF'
            }))}
            selectedItem={selectedItem}
            spinType={spinType}
            volume={volume}
            isMuted={isMuted}
            onSpinEnd={() => setIsSpinning(false)}
          />

          {spinCounts[spinType] > 0 ? (
            <>
              <button onClick={handleSpin} style={{ marginRight: '5px' }} className={`btn btn-${spinType === 'basic' ? 'primary' : spinType === 'premium' ? 'danger' : 'warning'} mt-3`} disabled={isSpinning}>
                Spin
              </button>
              <button onClick={handleSpinX10} style={{ marginLeft: '5px' }} className={`btn btn-${spinType === 'basic' ? 'primary' : spinType === 'premium' ? 'danger' : 'warning'} mt-3`} disabled={isSpinning || spinCounts[spinType] < 10}>
                Spin x10
              </button>
            </>
          ) : (
            <p style={{ fontWeight: 'bold' }} className="mt-3">No {spinType} spins left</p>
          )}

          <div className="spin-info mt-4">
            <p className='text-shadow my-spin'>Basic Spins Left: {spinCounts.basic}</p>
            <p className='text-shadow my-spin'>Premium Spins Left: {spinCounts.premium}</p>
            <p className='text-shadow my-spin'>Jackpot Spins Left: {spinCounts.jackpot}</p>
          </div>

          {isModalOpen && (
            <div className="custom-modal">
              <div className="custom-modal-content">
                <h2>Congratulations!</h2>
                {selectedItems && selectedItems.length > 0 ? (
                  <div>
                    You won: <br />
                    {selectedItems.map((item, index) => (
                      <span key={index}>{item.name}<br /></span>
                    ))}
                  </div>
                ) : (
                  <p>You won: {spinConfigurations[spinType][selectedItem]?.name}</p>
                )}
                <button onClick={closeModal}>Close</button>
              </div>
            </div>
          )}

          {isErrorModalOpen && (
            <div className="custom-modal">
              <div className="custom-modal-content">
                <h2>Error</h2>
                <p>{errorMessage}</p>
                <button onClick={closeErrorModal}>Close</button>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-3">
          <HistoryTable userId={user.userId} refreshFlag={refreshFlag} />
        </div>
      </div>
    </div>
  );
};

export default SpinWheel;
